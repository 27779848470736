import React from 'react'
import Socials from './Socials'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
export default function Footer(props) {
    return (
        <div className="footer" style= {props.bgBlack  && {backgroundColor:'black'}}>
            <Grid container spacing={12}>
                <Grid item xs={12} className="text-center">
                    <Socials align= 'center' color = {props.white ? 'White' :'Black'}/>
                </Grid>
                <Grid item xs={12} className="text-center">
                    <Link to="/pp" style={{color: props.white? 'white' :'black'}}>Politícas de privacidad</Link>
                </Grid>
                <Grid item xs={12} className="text-center" >
                    <span to="/pp" style={{color: props.white? 'white' :'black', fontSize:12}}>Copyright© 2020 - Todos los Derechos reservados a 4Think Digital Solutions </span>
                </Grid>
            </Grid>
        </div>
    )
}
