import React from 'react'
import PropTypes from 'prop-types'
import blue from '@material-ui/core/colors/blue'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames';
import '../../css/slide.css';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import Header from '../../common/Header';
import Social from '../../common/Socials';
import CardService from '../../components/CardService';
const styles = {
    root: {
        backgroundColor: 'black !important',
        height: '100%',
        paddingTop: 92,
        paddingBottom: 90,

    },
    rootMobileLandscape: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    media: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
            maxHeight: '100%'
        }
    },
    mediaMobile: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    mediaMobileLandscape: {},
    mediaBackground: {
        backgroundColor: 'black',
        height: '100%',
        textAlign: 'center'
    },
    mediaBackgroundMobile: {
        height: '100%'
    },
    mediaBackgroundMobileLandscape: {
        height: '100%',
        flex: '1 1',
        alignSelf: 'stretch'
    },
    text: {
        textAlign: 'center',
        maxWidth: '80%',
        margin: '0 auto',
        paddingTop: 32,
        zIndex:99
    },
    textMobile: {
        paddingTop: 30,
        '& $title': {
            marginBottom: 8
        }
    },
    textMobileLandscape: {
        minWidth: 300,
        maxWidth: 'calc(50% - 48px)',
        padding: '24px 24px 128px',
        flex: '0 1',
        alignSelf: 'center',
        textAlign: 'left',
        margin: 0
    },
    title: {
        font: 'Regular 137px/222px var(--unnamed-font-family-brume)',
        color: 'var(--unnamed-color-ffffff)',
        textAlign: 'left',
        font: 'Regular 137px/222px Brume',
        letterSpacing: 0,
        color: '#FFFFFF',
        opacity: 1
    },
    subtitle: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '18px',
        margin: 0,
        color: '#fff'
    }
}

function Index(props) {
    const {
        classes,
        media,
        mediaBackgroundStyle,
        subtitle,
        title,
        mobile,
        landscape,
        image,
        video,
        ...other
    } = props

    const mobileLandscape = mobile && landscape

    return (
        <div>
          <Header />
                <div
                    className={classNames(classes.root, {
                        [classes.rootMobile]: mobile,
                        [classes.rootMobileLandscape]: mobileLandscape
                    })}
                    {...other}
                >
                    <div
                        className={classNames(classes.mediaBackground, {
                            [classes.mediaBackgroundMobile]: mobile,
                            [classes.mediaBackgroundMobileLandscape]: mobileLandscape
                        })}
                    >
                        <div className={classNames(classes.media, {
                            [classes.mediaMobile]: mobile,
                            [classes.mediaMobileLandscape]: mobileLandscape
                        })}>
                            <div
                                className={classNames(classes.text, {
                                [classes.textMobile]: mobile,
                                [classes.textMobileLandscape]: mobileLandscape
                                })}
                            >
                                  <CardService/>
                            </div>
                            {image}
                        </div>
                    
                    </div>
                </div>
            <Social align="right" color="White" />
        </div>
    )
}

export default withStyles(styles)(Index)