import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Container from '@material-ui/core/Container';
import Axios from 'axios';
import apiConfig from '../api-config';
import CategoryMenu from '../components/CategoryMenu';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: 'black',
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
  }));

export default function Projects() {
    const classes = useStyles();
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState({});

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = () =>{
        Axios.get(`${apiConfig.urlApi}projects`)
        .then(res => {
            if(res.data.success){
                setProjects(res.data.projects);
                console.log(res.data)
            }
        });
    }

    const openProject = (id) =>{
        getProject(id);
        handleOpen();
    }
    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
   
    const getProject = (id) => {
        setSelectedProject(projects.filter(project => project.id == id)[0]);
    };
    return (
        <div className="projects fix-header">
            <Header  />
            <section  className="section-projects">
                <CategoryMenu />
                <Container className="pt-10 pb-10 pl-10">
                    <div className={classes.root}>
                        <GridList cellHeight={269} className={classes.gridList} cols={1} >
                            {projects.map((project) => (
                            <GridListTile key={project.id} cols={1} className="card-project" onClick={() => openProject(project.id)} >
                                <img src={apiConfig.urlMedia+project.cover} alt={project.name} />
                            </GridListTile>
                            ))}
                        </GridList>
                    </div>
                </Container>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="card-project-modal"
            >
                {
                   selectedProject && (<div>
                        <Paper className="card-project-info wow animate__animated animate__backInDown" >
                            <CloseIcon className="close-icon" onClick={handleClose} />
                            <p style={{ padding:20 }}>Cliente:</p>
                            <h3 className="title-paper wow animate__animated animate__bounceInLeft" style={{ height: 60 }}>{selectedProject.name}</h3>
                            <p style={{ height: 80, padding:20 }}>{selectedProject.desciption}</p>
                            <button className="center-btn wow animate__animated animate__bounceInUp" >
                                <a target="_blank"  href={selectedProject.web}>Ir al sitio</a>
                            </button>
                        </Paper>
                
                        <div className="card-project-banner">
                            <img src={apiConfig.urlMedia+selectedProject.banner} />
                        </div>
                    </div>
                   )
                }
            </Modal>
            <Footer  white bgBlack />
        </div>
    );
}