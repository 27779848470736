import React from 'react';
import Container from '@material-ui/core/Container';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Grid from '@material-ui/core/Grid';
import CarouselMkt from '../../components/CarouselMkt';
import { Link } from 'react-router-dom';

export default function Design() {
    return (
        <div >
            <Header  />
            <section style={{backgroundColor:'black',  paddingTop: 92}} >
                <CarouselMkt design />
            </section>
            <section className="bg-design-1" >
                <Container  >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                            </Grid>
                            <Grid item xs={12} className="pt-5 pb-5">
                                <h1 className="title-service text-white">Branding</h1>
                                <h1 className="subtitle-service text-white">e identidad visual</h1>
                                <p className="text-service   pt-2 text-white">
                                No sólo nos encargamos de crear tu logotipo sino de crear un estilo único para tu marca desde la primera letra hasta tus necesidades en impresos.
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-design-2 " >
                <Container >
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="pt-5">
                                <h1 className="title-service">Fotografía</h1>
                                <h1 className="subtitle-service">de producto</h1>
                                <p className="text-service pt-2">
                                    Capturamos el mejor ángulo para mostrar tu negocio, tus productos y tu sonrisa.
                                </p>
                            </Grid>
                            <Grid item xs={12} >
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>

            <section style={{backgroundColor:'#1C1C1C'}} >
                <Container >
                    <div className="flex-grow pt-5 pb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                            <video className="video-design"  loop controls>
                                <source src="../video/lema.mp4" type="video/mp4" />
                            </video>
                            </Grid>
                            <Grid item xs={12} className="pt-15">
                                <h1 className="title-service text-white">Video</h1>
                                <h1 className="subtitle-service text-white">corporativo</h1>
                                <p className="text-service text-white   pt-2">
                                Muestra al mundo lo que haces, comparte el día a día de tu equipo o la función de tus productos y sus beneficios.
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-desing-create" >
                <Container >
                    <div className="flex-grow" style={{height:150}}>
                        <div className="text-center">
                            <h1 className="title-general text-white text-left wow animate__animated animate__bounceInUp">Creemos juntos</h1>
                            <Link to="/contact">
                                <span className="subtitle-footer text-white text-left">{'Contáctanos >'}</span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer bgBlack white  />
        </div>
    );
}