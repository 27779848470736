import React from 'react'

export default function Person(props) {

    return (
        <div style={{width:'100%'}}>
            <img className="person wow animate__animated animate__fadeInUp" src={`/img/${props.image}`}   onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} />
        </div>
    )
}
