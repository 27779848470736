import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Grid from '@material-ui/core/Grid';
import Person from '../components/Person';
import { Link } from 'react-router-dom';

export default function Services() {

    const [showPersons, setShowPerson] = useState({
        jLedesma : false,
        dLedesma : false,
        jjLedesma : false,
        aPerez : false,
        pFuentes : false,
    });


    const activePerson = (status,person) =>{
        setShowPerson({...showPersons, [person] : status});
    }


    return (
        <div >
            <Header />
            <section className="fix-header">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100 wow animate__animated animate__backInDown" src="/img/about/banner.jpg" alt="Team" />
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ backgroundColor: 'black' }} >
                {/* <Container > */}
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={12} class="wow animate__animated animate__bounceInRight  pt-5 pb-5">
                                <div className="text-center">
                                    <img className="img-responsive pb-2 logo-about" src="/img/logo_white.jpg" />
                                </div>
                                <p className="text-center text-service text-white  pt-2">
                                    Nuestro principal objetivo es ayudar a las empresas a crecer. Apoyar
                                    a los clientes para dar lo máximo de manera online
                                </p>
                                <p className="text-center text-service text-white  pt-2">
                                    Queremos ser una de las agencias de marketing líder en
                                    México y E.U.A y Latinoamérica. Crear valor y marcar la
                                    diferencias en la manera que la gente vende por internet.
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <img className="img-responsive wow animate__animated animate__bounceInDown" src="/img/about/about.jpg" />
                            </Grid>
                        </Grid>
                    </div>
                {/* </Container> */}
            </section>
            <section className="bg-about-1" >
                <Container className="pt-5 pb-5">
                    <div className="flex-grow">
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="wow animate__animated animate__bounceInLeft">
                                <h1 className="title-service">Desarrollamos ideas, creamos soluciones y garantizamos resultados.</h1>
                                <p className="text-service   pt-2">Daremos soluciones y estrategias para que
                                las empresas impacten con su contenido
                                y sus productos.

                                Dejaremos huella y daremos inspiración, por medio del trabajo en conjunto con las empresas, para enamorar a los clientes.

                                </p>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            <section style={{ backgroundColor: 'black' }} >
                <Container className="pt-5">
                    <div className="text-center">
                        <h1 className="title-about text-white mb-5">Conoce al equipo</h1>
                    </div>
                    <div className="flex-grow pt-5">
                        <Grid  container spacing={6}  >
                            <Grid  xs={12}  >
                                <Person right onMouseEnter={() => activePerson (true,'jLedesma')} 
                                onMouseLeave={() => activePerson (false, 'jLedesma')} 
                                image="about/juan.jpg" name="José Juan Ledesma" position="Líder general"/>
                            </Grid>
                            <Grid  xs={12}>
                                <h1 className="subtitle-service text-white">Juan Ledesma  <br/><span className="text-white full-text subtitle-about">Líder General de 4Think</span></h1>
                                <div className="animate__animated animate__backInRight">
                                    <p className="text-white about-desc text-justify">
                                        CEO Y FUNDADOR de LEMA de Querétaro y LEMA TRANSPORT INC. <br/>
                                        Director General de Box Keper.
                                        Comprometido con el mundo, tenaz y perseverante, apasionado por transformar la vida de las personas. 
                                    </p>
                                    <p className="text-white about-desc text-justify">
                                        Soy empresario de la vida, me apasiona ver el mundo transformarse y ser parte de ello. El tiempo, el estudio y la práctica te dan las herramientas para ser mejor cada día mejor persona. 
                                        <br/><span class="quote"> "Un deseo no cambia nada , una acción lo cambia todo".</span>
                                    </p>
                                </div>
                            </Grid>
                            <Grid  xs={12} >
                                <Person onMouseEnter={() => activePerson (true,'dLedesma')} 
                                onMouseLeave={() => activePerson (false, 'dLedesma')} image="about/dul.jpg" name="José Juan Ledesma" position="Líder general" />
                            </Grid>
                            <Grid  xs={12}>
                                <h1 className="subtitle-service text-white ">Dulce Cárdenas  <br/><span className="text-white full-text subtitle-about">Líder de Diseño</span></h1>
                                    <div className="animate__animated animate__backInRight">
                                        <p className="text-white about-desc text-justify">
                                            Lic. en Artes Visuales<br/>
                                            con especialidad en Diseño Gráfico<br/>
                                            Positiva, sensible y empática.<br/> 
                                            Me apasiona el hecho de poder crear una imagen desde cero, experimentar con la composición y encontrar diferentes posibilidades para representar una idea o concepto.
                                        </p>
                                        <p className="text-white about-desc text-justify">
                                            Tengo 5 años de experiencia dentro del mundo de la ilustración, en donde he podido ser entrevistada para TVUAQ y VANS México, además de haber sido conferencista he brindado talleres de dibujo y participado en eventos culturales como exposiciones colectivas y ventas de Arte para el Museo de la Ciudad de Querétaro, BoardDripper
                                            <br/><span class="quote"> "Diseñadora de día, ilustradora de noche, persona normal los domingos".</span>
                                        </p>
                                    </div>
                            </Grid>
                            
                            <Grid  xs={12}>
                                <Person right image="about/ale.jpg" onMouseEnter={() => activePerson (true,'aPerez')} 
                                onMouseLeave={() => activePerson (false, 'aPerez')} name="José Juan Ledesma" position="Líder general" />
                            </Grid>
                            <Grid  xs={12}>
                                <h1 className="subtitle-service text-white">Alejandra Pérez <span className="text-white full-text subtitle-about">Líder de social media.</span></h1>
                                <p className="text-white full-text subtitle-about"></p>
                                <div className="animate__animated animate__backInRight">
                                <p className="text-white about-desc  text-justify">
                                    Artista Visual.<br/>
                                    Soy una person Responsable /Sensible y Detallista
                                    Disfruto tomar fotos y me apasiona conocer lugares nuevos, pero lo que realmente amo es ir a conciertos y festivales.
                                </p>
                                <p className="text-white about-desc text-justify">
                                Uno de mis mayores logros personales ha sido, que la fotografía me ha llevado a exponer mi trabajo con muchas personas. Logré abrir un blog y plasmar todo lo que tengo en mi cabeza.
                                <br/><span class="quote"> "Lo que te crees es lo que creas".</span></p>
                                </div>
                            </Grid>
                            <Grid  xs={12}>
                                <Person onMouseEnter={() => activePerson (true,'pFuentes')} 
                                onMouseLeave={() => activePerson (false, 'pFuentes')} image="about/pao.jpg" name="José Juan Ledesma" position="Líder general" />
                            </Grid>
                            <Grid  xs={12}>
                                <h1 className="subtitle-service text-white">Paola Fuentes  <br/><span className="text-white full-text subtitle-about">Líder de Marketing</span></h1>
                                <div className="animate__animated animate__backInRight">
                                    <p className="text-white about-desc text-justify">
                                        Licenciada en Comunicación. <br/>
                                        Organizada con la vida, sociable y comprometida. 
                                        Certificación de Facebook 400-101. 
                                        Con el paso de los años, me enamoré del mundo online y la forma de conectar con millones de personas a través de estas herramientas digitales. 
                                        Trabajar con pequeñas y grandes empresas ha sido algo súper satisfactorio pues aprendes de todo lo que te rodea. 
                                    </p>
                                    <p className="text-white about-desc text-justify">
                                        Cuentas que me respaldan y me fascina platicar de ellas, en las que estuve colaborando como estratega de marketing son: Alfombristas Mexicanos, Bicuni, Josecho y medios digitales como: Diario de Querétaro, El Universal. 
                                        <br/><span class="quote"> "La magia es eso que sucede todos los días, exactamente cuando el universo te cambia el proceso de los planes que tenías".</span>
                                    </p>
                                </div>
                            </Grid>
                            <div className="center-content-profile">
                                <Grid  xs={12}  >
                                    <Person onMouseEnter={() => activePerson (true,'jjLedesma')} 
                                    onMouseLeave={() => activePerson (false, 'jjLedesma')}  image="about/juanjo.jpg" name="José Juan Ledesma" position="Líder general" />
                                </Grid>
                                <Grid  xs={12}  className="center-profile">
                                    <h1 className="subtitle-service text-white">Juan José Cárdenas  <br/><span className="text-white full-text subtitle-about">Líder de Desarrollo</span></h1>
                                    <div className="animate__animated animate__backInRight">
                                        <p className="text-white about-desc text-justify">
                                            Ing. en Sistemas Computacionales<br/>
                                            Autodidacta, motivador y visionario. Me apasiona el hecho de aprender nuevas tecnologías, resolver problemas que impacten en las personas, no suelo ser conformista y siempre trato de actualizarme..
                                        </p>
                                        <p className="text-white about-desc text-justify">
                                        En el mundo de la tecnología y en la vida siempre he buscado la mejor forma y efectiva para solucionar cualquier problema, gracias a ello he podido presentarme en la GAMESCOM Alemania, así mismo como expositor en Talen Land Guadalajara, y conferencista en Unity Meetup Querétaro.
                                            <br/><span class="quote"> "Si lo puedes imaginar, lo puedes programar".</span>
                                        </p>
                                    </div>
                                </Grid>
                            </div>
                           
                           
                        </Grid>
                    </div>
                </Container>
            </section>
            <section className="bg-about-2" >
                <Container >
                    <div className="flex-grow " style={{ height: 150 }}>
                        <div className="text-center">
                            <h1 className="title-general text-white text-left">Pensemos juntos</h1>
                            <Link to="/contact">
                                <span className="subtitle-footer text-white text-left">{'Contáctanos >'}</span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer white bgBlack />
        </div>
    );
}